import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from "gatsby"
import IntuitivelyImg from "../../assets/images/group-mail/intuitively-cross-platform.webp"
import IntuitivelyMImg from "../../assets/images/group-mail/intuitively-cross-platform-m.webp"
import GroupMailMImg from "../../assets/images/group-mail/group-mail-m.webp"
import QuickInitiationMImg from "../../assets/images/group-mail/quick-initiation-m.webp"
import GroupingSmoothlyMImg from "../../assets/images/group-mail/grouping-smoothly-m.webp"
const img = "/img/features/BlueMail_Features_Conversation_v2.png"

const GroupMail = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Group Mail | BlueMail App"
        postDescription="BlueMail Groups enable you to define groups of people you regularly communicate."
        postImage={img}
        postURL="features-functions/group-mail"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid group-mail mt-fs w-707 r">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  <span>Group</span> Mail
                </h1>
                <p>
                  Group Mail by BlueMail allows you to communicate with groups
                  of people that you regularly connect with. Instead of adding
                  each recipient individually to your email, simply start typing
                  the Group’s name within the TO field, select from one of the
                  predefined groups, and your email is ready to be sent to your
                  entire group. It’s that easy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={GroupMailMImg} alt="Group Mail" />
            </div>
          </div>
        </div>
        <div className="container-fluid quick-initiation w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>Quick</span> initiation
                </h2>
                <p>
                  Group Mail streamlines the process of sending emails to a
                  group, allowing you to focus on your message instead of the
                  recipient list. This quick initiation of email feature is
                  perfect for those who need to send frequent updates or
                  reminders to a group of people, such as project managers or
                  team leaders.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={QuickInitiationMImg} alt="Quick initiation" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>Intuitively</span> Cross platform
                </h2>
                <p>
                  Group Mail natively sync across all your devices wherever you
                  use BlueMail (appearing right below your Folder List),
                  enabling you to always have your groups available at the tips
                  of your fingers.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={IntuitivelyImg}
                alt="Intuitively Cross platform"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={IntuitivelyMImg}
                alt="Intuitively Cross platform mobile"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid grouping-smoothly w-707 r mb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h2>
                  Grouping <span>smoothly</span>
                </h2>
                <p>
                  Additionally, as part of the shared BlueMail experience, all
                  BlueMail users who are members of that group will now be able
                  to see and interact with the new group in their Group Mail tab
                  going forward, keeping you and your teammates, friends, or
                  family in sync. You can create a group from scratch or choose
                  to create one directly from a previous multi-person email, and
                  be sure to add a nice looking photo or avatar to it stand out
                  in your mail list.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5">
              <img src={GroupingSmoothlyMImg} alt="Grouping smoothly" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default GroupMail

export const query = graphql`
  query GroupMailPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
